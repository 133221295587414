<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card grid-list-md>
      <ModelTitle title="Customer Price" @close="close()" />

      <v-card-text v-if="CustomerPrice">
        <v-row>
          <v-col cols="12">
            <div class="title" v-if="CustomerPrice.Product">
              {{ CustomerPrice.Product.name }}
            </div>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              dense
              autofocus
              :hide-details="priceErrors.length === 0"
              :error-messages="priceErrors"
              @focus="$event.target.select()"
              class="right-input"
              v-model="CustomerPrice.price"
              label="Price"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import customerPriceService from "@/modules/Customer/CustomerPrice/service.js";
// import productService from "@/modules/Product/service";
const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-customer-price",
  props: {
    editDialog: {
      default: false,
    },
    editPrice: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      phoneSearch: null,
      selectedProduct: null,
      CustomerPrice: null,
      Products: [],
    };
  },
  mixins: [validationMixin],
  validations: {
    CustomerPrice: {
      price: {
        required,
      },
    },
  },
  watch: {
    editDialog(val) {
      // this.CustomerPrice = this.editPrice;
      this.getPriceById();
      this.dialog = val;
    },
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    priceErrors() {
      const errors = [];
      if (!this.$v.CustomerPrice.price.$dirty) return errors;
      if (!this.$v.CustomerPrice.price.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    },
  },
  methods: {
    getPriceById() {
      this.loading = true;
      return customerPriceService.getById(this.editPrice.id).then((result) => {
        // console.log('result, result', result)
        this.CustomerPrice = result.data;
        this.loading = false;
      });
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.CustomerPrice = {
        price: 0,
        CustomerId: null,
        ProductId: null,
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.loading = true;
        // this.CustomerPrice.CustomerId = this.CustomerId;
        // console.log("customer price", this.CustomerPrice);
        const id = this.CustomerPrice.id;
        delete this.CustomerPrice.id;
        return customerPriceService
          .update(id, this.CustomerPrice)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Customer Price is created",
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
